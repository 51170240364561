import * as React from 'react';
import * as C from './style';
import Copyright from './Copyright';

import logoImage from './../../img/logo_type.svg';

type FooterProps = {
  mt?: {};
};

const messages = [
  {
    'text': 'イラッシャイマセ'
  },
  {
    'text': 'ヨウコソ'
  },
  {
    'text': '最高なCoffee届けます'
  },
  {
    'text': 'Welcome'
  },
  {
    'text': 'コンニチワ'
  }
];

const selectedNum = Math.floor(Math.random() * messages.length);

const Footer: React.FunctionComponent<FooterProps> = ({ mt }) => {
  return (
    <C.Wrapper mt={mt}>
      <C.Contents>
        <C.Col>
          <C.Logo>
            <a
              href="https://melhor-coffee.shop/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logoImage} alt="Melhor Coffee" />
            </a>
            <C.Tooltip>\ {`${messages[selectedNum].text}`} /</C.Tooltip>
          </C.Logo>
        </C.Col>
        <C.Col>
          <C.List>
            <C.ListItem>
              <a title="オンラインストア" href="https://melhor-coffee.shop/" target="_blank" rel="noopener noreferrer">オンラインストア</a>
            </C.ListItem>
            <C.ListItem>
              <a title="LINEでお問い合わせ" href="https://lin.ee/1LH1W9Y" target="_blank" rel="noopener noreferrer">LINEでお問い合わせ</a>
            </C.ListItem>
            <C.ListItem>
              <a title="利用規約" href="https://melhor-coffee.shop/terms" target="_blank" rel="noopener noreferrer">利用規約</a>
            </C.ListItem>
            <C.ListItem>
              <a title="プライバシーポリシー" href="https://melhor-coffee.shop/privacy_policy" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>
            </C.ListItem>
            <C.ListItem>
              <a title="特定商取引法に関する表記" href="https://melhor-coffee.shop/tokushoho" target="_blank" rel="noopener noreferrer">特定商取引法に関する表記</a>
            </C.ListItem>
          </C.List>
        </C.Col>
      </C.Contents>

      <Copyright />
    </C.Wrapper>
  );
};

export default Footer;
