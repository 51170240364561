import { IoIosArrowUp } from 'react-icons/io';
import React from 'react';
import ScrollUpButtonWrapper from './style';

interface DefualtProps { }

const ScrollUpButton: React.FC<DefualtProps> = props => {
  return (
    <ScrollUpButtonWrapper {...props}>
      <IoIosArrowUp />
    </ScrollUpButtonWrapper>
  );
};

export default ScrollUpButton;
