import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import {
  typography,
  color,
  space,
  fontSize,
  fontFamily,
  width,
} from 'styled-system';
import { theme } from '../../theme';
import { DefaultStyledSystemProps } from '../../types/Common';
import mq from '../../theme/media';

interface DefaultProps {
  fontSize?: {};
  width?: {};
  mt?: {};
  mb?: {};
  mr?: {};
  ml?: {};
  px?: {};
  py?: {};
  pt?: {};
  pb?: {};
}

export const Wrapper = styled.footer<DefaultStyledSystemProps>`
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  ${space}

  ${mq.tab`
    padding: 0 4%;
  `}

  ${mq.down`
    padding: 0 4%;
  `}
`;

export const Contents = styled.div<DefaultStyledSystemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq.down`
    flex-direction: column;
  `}
`;

export const Col = styled.div<DefaultStyledSystemProps>`
  display: flex;

  &:nth-child(odd) {
    width: 30%;

    ${mq.tab`
      width: 25%;
    `}

    ${mq.down`
      width: 100%;
    `}
  }

  &:nth-child(even) {
    width: 70%;

    ${mq.tab`
      width: 75%;
    `}

    ${mq.down`
      width: 100%;
    `}
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  background-color: #fff;
  pointer-events: none;
  padding: 0.4rem 0.8rem;
  border-radius: 3px;
  color: #333;
  font-size: 10px;
  bottom: 90%;
  left: 50%;
  opacity: 0;
  white-space: nowrap;
  visibility: hidden;
  z-index: 999;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transform: translate3d(-50%, 0, 0);
  transition: 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid #fff;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Logo = styled.div<DefaultStyledSystemProps>`
  position: relative;
  width: 117px;
  cursor: pointer;

  &:hover {
    ${Tooltip} {
      opacity: 1;
      visibility: visible;
      bottom: calc(100% + 10px);
    }
  }

  img {
    display: block;
    width: 100%;
  }
`;

export const List = styled.ul<DefaultStyledSystemProps>`
  width: 100%;
  text-align: right;
  letter-spacing: -0.4em;
  margin-right: -30px;

  ${mq.down`
    text-align: left;
    margin-top: 30px;
    margin-right: 0;
  `}
`;

export const ListItem = styled.li<DefaultStyledSystemProps>`
  display: inline-block;
  margin-right: 30px;
  letter-spacing: normal;

  ${mq.down`
    display: block;
    margin-top: 10px;
    margin-right: 0;
  `}

  a {
    display: block;
    font-weight: bold;
    font-size: 12px;

    &:link,
    &:visited,
    &:hover {
      color: ${theme.colors.black};
    }
  }
`;

// Copyright
export const CopyrightWrapper = styled.div<DefaultStyledSystemProps>`
  position: relative;
  ${space}
  text-align: center;
  background-color: ${themeGet('colors.white')};

  span {
    font-size: 12px;
  }
`;

export const CopyrightText = styled.p<DefaultStyledSystemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${space}
  ${fontSize}
  font-weight: bold;
  color: ${theme.colors.gray};
`;

export const Copy = styled.span<DefaultStyledSystemProps>`
  font-size: 22px;
  ${space}
  ${fontSize}
  font-weight: bold;
  color: ${theme.colors.gray};
  font-family: serif;
  line-height: 1;
`;
