const colors = {
  red: '#CB0011',
  white: '#fff',
  black: '#333',
  gray: '#9B9B9B',
  lightGray: '#F0F0F0',

  transparent: 'transparent',
  textColor: '#292929',
  cTextColor: '#B5B5B5',
  lightRed: '#FFEAEF',
  blue: '#00A0E8',
  lightBlue: '#E4F5FD',
  yellow: '#FFBD41',
  disabledBg: '#E3E3E3',
  disabledText: '#4D5D6E',
  disabledTabText: '#BCBCBC',
  description: '#818181',
  broder: '#707070',
  lightBorder: '#E0E0E0',
  bgColor: '#FCFCFC',
  bgBorder: '#FAFAFA',

  // old
  lightTextColor: '#757575', // 4
  inactiveField: '#F7F7F7', // 5
  inactiveColor: '#767676', // 6
  inactiveBG: '#e6e6e6', // 7
  borderColor: '#DBDBDB', // 8
  lightBorderColor: '#ededed', // 9
  primary: '#D10068', // 10
  primaryHover: '#D10068' // 11
};

export default colors;
