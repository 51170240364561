import * as React from 'react';
import { CopyrightWrapper, CopyrightText, Copy } from './style';

type DefaultProps = {};

const Copyright: React.FC<DefaultProps> = ({ }) => {
  return (
    <CopyrightWrapper pt={[6, 7]} pb={[6, 4]}>
      <CopyrightText fontSize={[2, 3]}>
        <Copy mr={[1, 2]}>&copy;</Copy>
        <span>{new Date().getFullYear()} Melhor Coffee</span>
      </CopyrightText>
    </CopyrightWrapper>
  );
};

export default Copyright;
