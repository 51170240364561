/* eslint-disable @typescript-eslint/no-require-imports */
import { Link } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { navigate } from '@reach/router';
import React, { useState } from 'react';
import ScrollToTop from 'react-scroll-up';

// services
import SmoothScrolling from './../services/anchorLink';

// components
import Footer from '../components/Footer/index';
import ScrollUpButton from './ScrollUpButton';

// styles
import { theme } from '../theme';

import { RevealGlobalStyles } from 'react-genie';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './../scss/base.scss';
import './../scss/reset.scss';
import './../scss/style.scss';

// images
import LogoImage from './../img/logo.svg';

type LayoutProps = {
  children: React.ReactNode;
};

const TemplateWrapper: React.FC<LayoutProps> = ({ children }) => {
  const [hoverPCMenu, setPCMenuHover] = useState(false);

  const [menuOpen, setMenu] = useState(false);

  const handleBtnClick = () => setMenu(!menuOpen);

  const transitions = useTransition(menuOpen, null, {
    config: { duration: 100 },
    from: { opacity: 0, transform: 'translateY(-10%)' },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: { opacity: 0, transform: 'translateY(-10%)' }
  });

  // メニューhover時
  const toggleHover = () => {
    setPCMenuHover(!hoverPCMenu);
  };

  return (
    <ThemeProvider theme={theme}>
      <RevealGlobalStyles />
      <>
        {/* <header className="l-header">
          <div className="l-header__inner">
            <Link className="l-header__logo" to={'/'}>
              <img src={LogoImage} alt="" />
            </Link>

            <nav className="l-header__sp-nav">
              <div className="l-header__sp-nav__menu" onClick={handleBtnClick}>
                <div className="l-header__sp-nav__menu__inner">
                  <span className="l-header__sp-nav__menu__line" />
                  <span className="l-header__sp-nav__menu__line" />
                  <span className="l-header__sp-nav__menu__line" />
                </div>
              </div>
            </nav>

            {transitions.map(({ item, key, props }) => {
              return (
                item && (
                  <animated.div
                    style={{
                      ...props,
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100vh',
                      minHeight: 'inherit'
                    }}
                    className="menu"
                    key={key}
                  >
                    <div className="l-nav-conents">
                      <div className="l-nav-conents__inner">
                        <div className="l-nav-conents__head">
                          <div className="l-nav-conents__head__inner">
                            <div
                              className="l-nav-conents__head__close"
                              onClick={handleBtnClick}
                            />
                            <div className="l-nav-conents__head__logo">
                              <img
                                src={LogoImage}
                                alt="logo"
                                className="l-nav-conents__head__logo__image"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="l-nav-conents__body">
                          <Link
                            to={'/'}
                            onClick={handleBtnClick}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              トップページ
                            </span>
                          </Link>

                          <Link
                            to={'/about'}
                            onClick={handleBtnClick}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              About
                            </span>
                          </Link>

                          <Link
                            to={'/blog'}
                            onClick={handleBtnClick}
                            className="l-nav-conents__body__link"
                          >
                            <span className="l-nav-conents__body__link__text">
                              ブログ
                            </span>
                          </Link>

                        </div>
                      </div>
                    </div>
                  </animated.div>
                )
              );
            })}
          </div>
        </header> */}

        <div>{children}</div>
        <Footer mt={[9, 14]} />

        <ScrollToTop
          showUnder={300}
          duration={700}
          easing="easeInOutCubic"
          style={{ bottom: 30, right: 20, zIndex: 100 }}
        >
          <ScrollUpButton />
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default TemplateWrapper;
